import React, { useState } from "react"
import { NumericInput } from "@blueprintjs/core"
import { Line } from "react-chartjs-2"

const averageLastNDays = (dataset, days) => {
  const averaged = []

  for (let i in dataset) {
    const roll = +i - +days
    const min = roll > 0 ? roll : 0

    let av = 0
    for (let j = i; j > min; j--) {
      av += dataset[j]
    }
    averaged.push(av / days)
  }

  return averaged
}

const SummaryChart = ({ results, dates, ignorePP = false, classes }) => {
  const [days, setDays] = useState(7)

  let allDP = []
  let posDP = []
  let negDP = []
  let ppDP = []

  for (let i = 0; i < dates.length; i++) {
    const total = results[dates[i]]
    allDP.push(total.ALL)
    posDP.push(total.POS)
    negDP.push(total.NEG)
    ppDP.push(total.PP)
  }

  let defaultProps = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBackgroundColor: "#fff",
    pointBorderWidth: 0,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 0,
    pointHitRadius: 10,
    borderWidth: 3,
  }

  const blue = "66,153,225"
  const green = "72,187,120"
  const orange = "237,137,54"
  const red = "245,101,101"

  const data = {
    labels: [...dates],
    datasets: [
      {
        ...defaultProps,
        backgroundColor: `rgba(${blue}, 0.8)`,
        borderColor: `rgba(${blue}, 0.8)`,
        pointHoverBackgroundColor: `rgba(${blue}, 1)`,
        pointHoverBorderColor: `rgba(${blue}, 1)`,
        label: "All",
        data: averageLastNDays(allDP, days),
      },
      {
        ...defaultProps,
        backgroundColor: `rgba(${green}, 0.8)`,
        borderColor: `rgba(${green}, 0.8)`,
        pointHoverBackgroundColor: `rgba(${green}, 1)`,
        pointHoverBorderColor: `rgba(${green}, 1)`,
        label: "Negative",
        data: averageLastNDays(negDP, days),
      },
      {
        ...defaultProps,
        backgroundColor: `rgba(${red}, 0.8)`,
        borderColor: `rgba(${red}, 0.8)`,
        pointHoverBackgroundColor: `rgba(${red}, 1)`,
        pointHoverBorderColor: `rgba(${red}, 1)`,
        label: "Positive",
        data: averageLastNDays(posDP, days),
      },
    ],
  }

  if (!ignorePP) {
    data.datasets.push({
      ...defaultProps,
      backgroundColor: `rgba(${orange}, 0.8)`,
      borderColor: `rgba(${orange}, 0.8)`,
      pointHoverBackgroundColor: `rgba(${orange}, 1)`,
      pointHoverBorderColor: `rgba(${orange}, 1)`,
      label: "Equivocal",
      data: averageLastNDays(ppDP, days),
    })
  }

  const options = {
    maintainAspectRatio: false,
    legend: {
      position: "top",
    },
    scales: {
      xAxes: [
        {
          gridLines: false,
          ticks: {
            fontStyle: "bold",
            padding: 10,
          },
        },
      ],
      yAxes: [
        {
          gridLines: false,
          ticks: {
            fontStyle: "bold",
            padding: 10,
          },
        },
      ],
    },
  }

  return (
    <div className={`${classes} relative `}>
      <div className="flex justify-center mb-6 text-gray-700 absolute mt-12 left-0 ml-20">
        <p>Rolling average:</p>
        <NumericInput
          min={1}
          className="mx-2"
          style={{ width: 40 }}
          max={21}
          intent="primary"
          value={days}
          onValueChange={value => setDays(value)}
        />
        <p>days</p>
      </div>
      <Line data={data} options={options} />
    </div>
  )
}

export default SummaryChart
