/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResult = /* GraphQL */ `
  query GetResult($id: ID!) {
    getResult(id: $id) {
      id
      donorId
      user
      N1
      N2
      N3
      RP
      valid
      summary
      plate {
        id
        name
        date
        results {
          nextToken
        }
      }
    }
  }
`
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        donorId
        user
        N1
        N2
        N3
        RP
        valid
        summary
        plate {
          id
          name
          date
        }
      }
      nextToken
    }
  }
`
export const getPoolList = /* GraphQL */ `
  query GetPoolList($id: ID!) {
    getPoolList(id: $id) {
      id
      name
      date
      resultIds
    }
  }
`
export const listPoolLists = /* GraphQL */ `
  query ListPoolLists(
    $filter: ModelPoolListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPoolLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        date
        resultIds
      }
      nextToken
    }
  }
`
export const listPlates = /* GraphQL */ `
  query ListPlates(
    $id: ID
    $filter: ModelPlateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        date
        results {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getPlate = /* GraphQL */ `
  query GetPlate($id: ID!) {
    getPlate(id: $id) {
      id
      name
      date
      results {
        items {
          id
          donorId
          user
          N1
          N2
          N3
          RP
          valid
          summary
        }
        nextToken
      }
    }
  }
`
export const platesByDateSorted = /* GraphQL */ `
  query PlatesByDateSorted(
    $name: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    platesByDateSorted(
      name: $name
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        date
        results {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prefix
        addresses {
          nextToken
        }
      }
      nextToken
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      prefix
      addresses {
        items {
          id
          address
          name
        }
        nextToken
      }
    }
  }
`
export const getEmailAddress = /* GraphQL */ `
  query GetEmailAddress($id: ID!) {
    getEmailAddress(id: $id) {
      id
      address
      name
      user {
        id
        name
        prefix
        addresses {
          nextToken
        }
      }
    }
  }
`
export const listEmailAddresss = /* GraphQL */ `
  query ListEmailAddresss(
    $filter: ModelEmailAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        name
        user {
          id
          name
          prefix
        }
      }
      nextToken
    }
  }
`
export const getIndexStats = /* GraphQL */ `
  query GetIndexStats($id: ID!) {
    getIndexStats(id: $id) {
      id
      tests
      people
      centres
      positives
      dateCounts
    }
  }
`
export const listIndexStatss = /* GraphQL */ `
  query ListIndexStatss(
    $filter: ModelIndexStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIndexStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tests
        people
        centres
        positives
        dateCounts
      }
      nextToken
    }
  }
`
export const getFaqSection = /* GraphQL */ `
  query GetFaqSection($id: ID!) {
    getFAQSection(id: $id) {
      id
      open
      text
    }
  }
`
export const listFaqSections = /* GraphQL */ `
  query ListFaqSections(
    $filter: ModelFAQSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFAQSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        open
        text
      }
      nextToken
    }
  }
`
export const getSwabRequest = /* GraphQL */ `
  query GetSwabRequest($id: ID!) {
    getSwabRequest(id: $id) {
      id
      user
      amount
    }
  }
`
export const listSwabRequests = /* GraphQL */ `
  query ListSwabRequests(
    $filter: ModelSwabRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSwabRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        amount
      }
      nextToken
    }
  }
`
export const getChartDataRecord = /* GraphQL */ `
  query GetChartDataRecord($id: ID!) {
    getChartDataRecord(id: $id) {
      id
      date
      positive
      negative
      partial
      all
    }
  }
`
export const listChartDataRecords = /* GraphQL */ `
  query ListChartDataRecords(
    $filter: ModelChartDataRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChartDataRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        positive
        negative
        partial
        all
      }
      nextToken
    }
  }
`
