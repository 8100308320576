import { Colors } from "@blueprintjs/core"

export const isNPlus = N => {
  let trimmed = N.trim().toLowerCase()
  switch (trimmed) {
    case "+":
      return 1
    case "-":
      return 0
    case "no ct":
      return 0
    case "x":
      return 0
    default:
      let num = +trimmed
      return num > 40 ? 1 : 0
  }
}

export const ResultEnum = {
  POSITIVE: 1,
  NEGATIVE: 2,
  PARTIAL_POSITIVE: 3,
  INVALID: 4,
}

export const getResultStatus = result => {
  const { N1, N2, N3, RP } = result

  if (isNPlus(RP)) {
    let posCount = isNPlus(N1) + isNPlus(N2) + isNPlus(N3)

    if (posCount === 3) {
      return ResultEnum.POSITIVE
    } else if (posCount === 2 && isNX(N3)) {
      return ResultEnum.POSITIVE
    } else if (posCount === 0) {
      return ResultEnum.NEGATIVE
    } else {
      return ResultEnum.PARTIAL_POSITIVE
    }
  }
}

export const isNX = N => {
  return N.trim().toLowerCase() === "x"
}

export const findColor = ({ donorId, N1, N2, N3, RP, valid }) => {
  if (valid.toLowerCase().trim() === "no") {
    return Colors.GRAY2
  }

  if (donorId.toLowerCase().startsWith("con")) {
    return Colors.BLUE2
  }

  if (isNPlus(RP)) {
    let posCount = isNPlus(N1) + isNPlus(N2) + isNPlus(N3)

    if (posCount === 3) {
      return Colors.RED2 //full positive
    } else if (posCount === 2 && isNX(N3)) {
      return Colors.RED2
    } else if (posCount === 0) {
      return Colors.GREEN2
    } else {
      return Colors.ORANGE2 //partial
    }
  }

  return Colors.GRAY2
}
