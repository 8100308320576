import React, { useState, useEffect } from "react"
import { Collapse } from "@blueprintjs/core"

const FAQSection = ({ sections, onClickTab, functionLabel = "" }) => {
  const [openMap, setOpenMap] = useState({})

  useEffect(() => {
    let map = {}
    sections.forEach(s => (map[s.id] = s.open))
    setOpenMap(map)
  }, [])

  const handleButton = id => {
    const map = { ...openMap }
    map[id] = !map[id]
    setOpenMap(map)
  }

  const sectionComps = sections.map(section => {
    return (
      <div key={section.id} className="flex items-start ">
        <div className="md:w-128 mx-auto lg:w-3/4 text-gray-800 ">
          <button
            onClick={() => handleButton(section.id)}
            className="min-w-full p-4 m-2 border bg-white rounded border-gray-500 text-center outline-none focus:outline-none font-bold"
          >
            <div className="flex justify-between items-center text-left ">
              <p>{section.id}</p>
              <p
                className={`ml-8 ${
                  openMap[section.id] ? "text-red-500" : "text-blue-500"
                }`}
              >
                {openMap[section.id] ? "-" : "+"}{" "}
              </p>
            </div>
          </button>
          <Collapse isOpen={openMap[section.id]}>
            <ul className="w-10/12 mx-auto list-outside list-disc">
              {section.text.map((t, i) => (
                <li key={`open-section-${section.id}-${i}`}>
                  <p className="text-sm md:text-md lg:text-lg">{t}</p>
                </li>
              ))}
            </ul>
          </Collapse>
        </div>
        {onClickTab && (
          <button
            className="default-btn bg-red-500 hover:bg-red-600 text-red-100 hover:text-red-300 w-32 h-10 mt-4"
            onClick={() => onClickTab(section.id)}
          >
            {functionLabel}
          </button>
        )}
      </div>
    )
  })

  return (
    <div className="mt-4">
      <h3 className="text-lg font-semibold text-center mb-2 text-gray-900">
        Frequently Asked Questions
      </h3>
      {sectionComps}
    </div>
  )
}

export default FAQSection
