import React, { useEffect, useState } from "react"
import { navigate } from "@reach/router"
import gql from "graphql-tag"
import CalendarHeatmap from "react-calendar-heatmap"
import * as queries from "../graphql/queries"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import FAQSection from "../components/FAQSection"
import { apiKeyClient } from "../../gatsby-browser"
import "../layouts/calendarColors.css"
import ReactTooltip from "react-tooltip"
import content from "../../content/index.json"
import CountUp from "react-countup"
import { formatDate } from "../utils/core"
import SummaryChart from "../components/admin/summary/SummaryChart"

const getTooltipDataAttrs = value => {
  // Temporary hack around null value.date issue
  if (!value || !value.date) {
    return null
  }
  // Configuration for react-tooltip
  return {
    "data-tip": ` ${value.counts} tests completed on  ${value.date}`,
  }
}

const IndexPage = () => {
  const [data, setData] = useState({
    dates: [],
    tests: 0,
    centres: 0,
    people: 0,
    positives: 0,
  })
  const [chartData, setChartData] = useState({
    points: {},
    dates: [],
  })
  const [sections, setSections] = useState([])

  const fetchFAQSectons = async () => {
    const secs = await apiKeyClient.query({
      query: gql(queries.listFaqSections),
      fetchPolicy: "network-only",
      variables: {
        limit: 100000,
      },
    })
    if (secs.data.listFAQSections) {
      setSections(secs.data.listFAQSections.items)
    }
  }

  useEffect(() => {
    fetchFAQSectons()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        await apiKeyClient
          .query({
            query: gql(queries.listIndexStatss),
          })
          .then(({ data: { listIndexStatss } }) => {
            let { tests, positives, people, centres } = listIndexStatss.items[0]

            setData({
              dates: listIndexStatss.items[0].dateCounts.map(date =>
                JSON.parse(date)
              ),
              tests,
              positives,
              people,
              centres,
            })
          })

        await apiKeyClient
          .query({
            query: gql(queries.listChartDataRecords),
            variables: {
              limit: 10000,
            },
          })
          .then(({ data: { listChartDataRecords } }) => {
            let chartData = {}

            let dates = listChartDataRecords.items.map(item => item.date)

            const sortedDates = dates.sort((a, b) => {
              const dateA = new Date(a)
              const dateB = new Date(b)
              return dateA > dateB ? 1 : dateA < dateB ? -1 : 0
            })

            for (let item of listChartDataRecords.items) {
              const { date, positive, negative, partial, all } = item
              const formattedDate = formatDate(new Date(date))
              chartData[formattedDate] = {
                POS: positive,
                NEG: negative,
                PP: partial,
                ALL: all,
              }
            }
            setChartData({
              points: chartData,
              dates: sortedDates.map(date => formatDate(new Date(date))),
            })
          })
          .catch(err => {
            console.log(err)
          })
      } catch (err) {
        //oh no, something went wrong.
      }
    }

    fetchData()
  }, [])

  const { dates, tests, centres, people, positives } = data

  const duration = 2

  return (
    <>
      {tests > 0 && <ReactTooltip type="dark" place="top" effect="float" />}
      <Layout>
        <SEO title="Home" />

        <div className="mt-8">
          <div className="flex items-center justify-between flex-col">
            <div>
              <h1 className="text-gray-800 text-5xl font-bold font-sans mt-12 border-b-0 leading-10 lg:leading-14 lg:text-6xl">
                SBL Coronavirus <br /> Testing Results
              </h1>
              <h3 className="text-gray-700 text-lg font-bold mt-8 leading-6 lg:text-xl">
                So far, we have completed
                <span className="border-b-2 border-blue-500 mx-1 text-blue-500">
                  {tests && <CountUp end={tests} duration={duration} />}
                </span>
                tests for
                <span className="border-b-2 border-blue-500 mx-1 text-blue-500">
                  {people && <CountUp end={people} duration={duration} />}
                </span>
                health care workers <br /> in
                <span className="border-b-2 border-blue-500 mx-1 text-blue-500">
                  {centres && <CountUp end={centres} duration={duration} />}
                </span>
                locations.
              </h3>
              <h3 className="text-gray-700 text-lg font-bold mt-8 lg:text-xl">
                <span className="border-b-2 border-red-500 mr-1 text-red-500">
                  {positives && <CountUp end={positives} duration={duration} />}
                </span>
                of these tests have returned positive.
              </h3>
            </div>
            <div className="px-8 mt-12 max-w-64 w-128 md:mx-0 ">
              <CalendarHeatmap
                startDate={new Date("2020-01-01")}
                endDate={new Date()}
                values={dates}
                tooltipDataAttrs={getTooltipDataAttrs}
                classForValue={val => {
                  if (!val) {
                    return "color-empty"
                  }
                  const { counts } = val
                  const nearest20 = Math.ceil(counts / 20) * 20
                  if (nearest20 <= 160) {
                    return `color-${nearest20}`
                  }
                  return "color-160"
                }}
              />
            </div>
          </div>

          <div className="flex items-center justify-center mt-8 lg:mt-20">
            <button
              className="md:w-64 default-btn"
              onClick={() => navigate("/login")}
            >
              Continue
            </button>
          </div>

          <h2 className="text-gray-800 text-lg mt-10 text-center border-b-0 md:text-xl lg:text-2xl font-semibold">
            Our Tests to Date
          </h2>
          {chartData.dates.length > 0 && (
            <SummaryChart
              results={chartData.points}
              dates={chartData.dates}
              ignorePP={true}
              classes="h-196 w-96 md:w-196 mx-auto mt-10"
            />
          )}

          <div className="mt-16 text-gray-800">
            <h2 className=" text-lg mt-10 text-center border-b-0 md:text-xl lg:text-2xl font-semibold">
              {content.title}
            </h2>
            <div className="mt-8 text-sm px-2 md:w-128 md:text-md lg:text-lg mx-auto lg:w-196">
              {content.content.map((item, index) => (
                <p key={`item_before_${index}`} className="mb-4">
                  {item.text}
                </p>
              ))}
            </div>
          </div>
        </div>
        <FAQSection sections={sections} />
      </Layout>
    </>
  )
}

export default IndexPage
