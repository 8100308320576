import * as queries from "../graphql/queries"
import { getResultStatus, ResultEnum } from "./results"
import { userPoolsClient } from "../../gatsby-browser"
import gql from "graphql-tag"

export const formatUserName = name => {
  return name.replace(/([A-Z])/g, " $1").trim()
}

export const fDateBit = date => {
  return date < 10 ? "0" + date : date
}

export const formatDate = d => {
  return `${fDateBit(d.getDate())}-${fDateBit(
    d.getMonth() + 1
  )}-${d.getFullYear()}`
}

export const formatDateForStats = date => {
  return `${date.getFullYear()}-${fDateBit(date.getMonth() + 1)}-${fDateBit(
    date.getDate()
  )}`
}

const deduplicatePooledData = items => {
  let map = {}

  items.forEach(item => {
    let { donorId } = item
    if (!map[donorId]) {
      map[donorId] = []
    }
    map[donorId].push(item)
  })

  let arr = []

  Object.values(map).forEach(val => {
    if (val.length > 1) {
      //should only ever have 2 results.
      //get the non-pooled one.
      const nonPooled = val[0].pooled ? 1 : 0
      arr.push(val[nonPooled])
    } else {
      arr.push(val[0])
    }
  })

  return arr
}

//unravels the plate data, looking for pooled data and user data. Outputs a js object of date -> [Plate]
export const buildPlateData = async (items, user) => {
  let plates = []
  let loaded = 0
  for (let i in items) {
    //each plate.
    const item = items[i]
    const results = item.results.items
    if (results.length > 0) {
      let accepted = []
      //each item.
      for (let j in results) {
        let result = results[j]
        if (!user || result.user === user) {
          if (result.donorId.includes("_P")) {
            if (getResultStatus(result) === ResultEnum.NEGATIVE) {
              //look for the table lookup for this particular date and pool.
              const key = `${formatDate(
                new Date(item.date)
              ).trim()} - ${result.donorId.trim()}`
              try {
                const poolList = await userPoolsClient.query({
                  query: gql(queries.getPoolList),
                  variables: {
                    id: key,
                  },
                })
                if (poolList.data.getPoolList) {
                  loaded += poolList.data.getPoolList.resultIds.length
                  poolList.data.getPoolList.resultIds.forEach(id => {
                    accepted.push({
                      ...result,
                      donorId: id,
                      pooled: true,
                    })
                  })
                } else {
                  //if not found, just add the result
                  loaded++
                  accepted.push(result)
                }
              } catch (e) {
                //if something goes wrong, just add the result
                loaded++
                accepted.push(result)
              }
            }
          } else {
            loaded++
            accepted.push(result)
          }
        }
      }
      if (accepted.length > 0) {
        plates.push({
          ...item,
          results: {
            items: deduplicatePooledData(accepted),
          },
        })
      }
    }
  }

  //order the plates in a map by date.
  let map = {}

  plates.forEach(plate => {
    if (!map[plate.date]) {
      map[plate.date] = {
        isTabOpen: false,
        selectedPlate: null,
        loadedResults: null,
        plates: [],
      }
    }

    map[plate.date].plates.push(plate)
    map[plate.date].selectedPlate = plate.name
  })

  const ordered = {}

  Object.keys(map)
    .sort((a, b) => {
      let aD = new Date(a)
      let bD = new Date(b)
      return aD > bD ? 1 : aD < bD ? -1 : 0
    })
    .forEach(key => {
      ordered[key] = map[key]
    })

  return { noLoaded: loaded, platesByDate: ordered }
}
